import axios from "axios";
import authHeader from "./auth-header";

// list -------------------------------------------------------------
const list = async () => {
  return await axios({
    url: process.env.REACT_APP_SERVER_URL + '/api/role/list',
    method: 'post',
    headers: authHeader(),
  }).catch(function (error) {
    console.log(error.response.data);
  })
}

// default export ---------------------------------------------------
const role = {
  list
};

export default role;