import Countdown, { zeroPad } from "react-countdown";

const moment = require('moment-timezone');

export function CountDownToRace({heurec}) {
    return (
      <Countdown
        date={moment.tz(heurec, "HH:mm", 'Europe/Paris').unix() * 1000}
        renderer={({ hours, minutes, seconds, completed }) => {
          if (completed) {
            return <span className="countdownComplete">Race should start shortly ...</span>;
          } 
          else {
            return <><span className="label">Planned to start in</span><span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span></>
          }  
        }}
      />
    )
  }
  