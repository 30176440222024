import Countdown, { zeroPad } from "react-countdown";

const moment = require('moment-timezone');

const CET_SUFFIX = ' CET';

function RaceCountDown({waitingData, heure}) {
    return (
        <Countdown
            date={moment.tz(heure, "HH:mm", 'Europe/Paris').unix() * 1000}
            renderer={({ hours, minutes, seconds, completed }) => {
                if (!waitingData) {
                    return (<><span style={{display:'none'}}>XXX</span></>);
                }  else if (completed) {
                    return (
                        <span className="race_spectator_passed">Race should start shortly ...</span>
                    );
                }  else {
                    return (
                        <>
                            <span className="race_spectator_planned">
                                - Planned to start in {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                            </span>
                        </>
                    );
                }  
            }}
        />
    );
}

export function RaceSpectatorHeader({waitingData, heure, prefix, meeting, race, venue, label, distance, discipline}) {
    if (!heure) {
        return (
            <div className="race_spectator_header">
                <h1 className="race_spectator_title">
                    No more race today
                </h1>
            </div>
        )
    }
    return (
        <div className="race_spectator_header">
            <h1 className="race_spectator_title">
                <span className="race_spectator_prefix">{prefix}</span>
                {heure}{CET_SUFFIX}
                <span className="race_spectator_rc">{`R${meeting}C${race}`}</span>
                MEETING {meeting} - {venue} / RACE {race} - {label}
                <span className="race_spectator_distance">{distance}m</span>
                <span className="race_spectator_discipline">{discipline}</span>
                <span className="race_spectator_countdown"><RaceCountDown waitingData={waitingData} heure={heure}/></span>
            </h1>
        </div>
    );
}
  
  