import React from "react";
import { Layout, Button } from "antd";

const { Header, Content, Footer } = Layout;

export default function PublicLayout(props) {

  return (
    <Layout className="public_layout">
      <Header className="public_layout_header">
        <img src={`${process.env.PUBLIC_URL}/images/website/logo-mccloyd-data.svg`} alt="McLloyd" onClick={() => document.location.href = "https://data.mclloyd.com"} />
        <Button type="primary" className="login-header-button" href="https://data.mclloyd.com">Discover</Button>
      </Header>
      <Content className="public_layout_content">{props.children}</Content>
      <Footer className="public_layout_footer"><p>Version {`${process.env.REACT_APP_VERSION}`}</p></Footer>
    </Layout>    
  )
}