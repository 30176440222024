import { useEffect, useState } from "react";
import { ArrowDownOutlined, ArrowUpOutlined, LineOutlined } from "@ant-design/icons";

function format(value, decimal) {
    if (!value) {
        return undefined;
    }
    let str = '' + value;
    let index = str.indexOf('.');
    if (index===-1) {
        str += '.';
        index = str.indexOf('.');
    }
    str += '00000000000000';
    return str.substring(0, index + 1 + decimal);
}

function humanTcle(tcle) {
    if (!tcle) {
        return undefined;
    }
    let reste = tcle;
    const ms = reste % 1000;
    const sms = `${ms<100?'0':''}${ms<10?'0':''}${ms}`
    reste = (reste - ms) / 1000;
    const sec = reste % 60;
    const ssec = `${sec<10?'0':''}${sec}`
    reste = (reste - sec) / 60;
    const min = reste;
    const smin = `${min<10?'0':''}${min}`
    return `${smin}:${ssec}.${sms}`;
}

function RaceSpectatorPerformancesItem ({dataFull, dataSect, index, bestDoorTime, worstDoorTime}) {
    const [itemIndex, setItemIndex] = useState(0);
    const [font, setFont] = useState('black');
    const [color, setColor] = useState('white');
    const [border, setBorder] = useState('white');
    const [NumP, setNumP] = useState(0);
    const [PosC, setPosC] = useState(0);
    const [DpDp, setDpDp] = useState(0);
    const [Vit, setVit] = useState(0);
    const [Eprm, setEprm] = useState(0);
    const [EprmAlert, setEprmAlert] = useState(false);
    const [DRank, setDRank] = useState(0);
    const [DRankWin, setDRankWin] = useState(false);
    const [DRankBigWin, setDRankBigWin] = useState(false);
    const [DRankLoose, setDRankLoose] = useState(false);
    const [DRankBigLoose, setDRankBigLoose] = useState(false);
    const [VmoySection, setVmoySection] = useState(0);
    const [STcle, setSTcle] = useState(0);

    const [isBestDoorTime, setIsBestDoorTime] = useState(false);
    const [isWorstDoorTime, setIsWorstDoorTime] = useState(false);

    useEffect(() => {
        setItemIndex(index);
    }, [index]);
    
    useEffect(() => {
        setFont(dataFull.font);
        setColor(dataFull.color);
        setBorder(dataFull.border);
        setNumP(dataFull.NumP);
        setPosC(dataFull.PosC);
        setDpDp(dataFull.DpDp);
        setVit(dataFull.Vit * 3.6);
        setEprm(dataFull.Eprm);
        setEprmAlert(Math.abs(dataFull.Eprm) > 0 && Math.abs(dataFull.Eprm) < 1);
    }, [dataFull]);

    useEffect(() => {
        setDRank(dataSect.DRank);
        setDRankWin(dataSect.DRank > 0);
        setDRankBigWin(dataSect.DRank >= 3);
        setDRankLoose(dataSect.DRank < 0);
        setDRankBigLoose(dataSect.DRank <= -3);
        if (dataSect.VmoySection) {
            setVmoySection(dataSect.VmoySection * 3.6);
        } else {
            setVmoySection(null);
        }
        setSTcle(dataSect.STcle);
    }, [dataSect])

    useEffect(() => {
        setIsBestDoorTime(dataSect.STcle <= bestDoorTime)
        setIsWorstDoorTime(dataSect.STcle >= worstDoorTime)
    }, [dataSect, bestDoorTime, worstDoorTime])


    function reformatColor(color) {
        if (!color) {
            return '#888888';
        }
        if (color.startsWith('#')) {
            return color;
        }
        if (color.length === 6 && color.match(/^[a-f0-9A-F]*$/)) {
            return '#' + color;
        }
        return color
    }

    return (
        <tr className={`competitor competitor-${itemIndex%2?'odd':'even'} competitor-${PosC === 1 ? 'first' : 'other'}`}>
            <td className="column-competitor">
                <span
                    className="competitor-jersey"
                    style={{
                        color: reformatColor(font),
                        backgroundColor: reformatColor(color),
                        borderColor: reformatColor(border),
                    }}
                    >
                    {NumP}
                </span>
            </td>
            <td className="column-rank">{PosC}</td>
            <td className="column-speed">{format(Vit, 1)}</td>
            <td className={`column-tofirst ${ EprmAlert ? 'near-first' : ''}`}>{format(Eprm, 1) || '-'}</td>
            <td className="column-dst">{format(DpDp, 1)}</td>
            <td className="separate"></td>
            <td className={`column-sect-rank ${DRankBigLoose ? 'big-loose' : ''} ${DRankBigWin ? 'big-win' : ''}`}>
                { DRank === undefined ? ''
                : DRankWin ? <><ArrowUpOutlined /> {Math.abs(DRank)}</>
                : DRankLoose ? <><ArrowDownOutlined /> {Math.abs(DRank)}</>
                : <LineOutlined />
                }
                
            </td>
            <td className="column-sect-speed">{format(VmoySection,1)}</td>
            <td className={`column-sect-time ${isBestDoorTime ? 'best' : isWorstDoorTime ? 'worst' : ''}`}>
                {humanTcle(STcle)}
            </td>
        </tr>
    );
}

function RaceSpectatorPerformancesList({list, sectionalByNumP}) {

    const [bestDoorTime, setBestDoorTime] = useState(null);
    const [worstDoorTime, setWorstDoorTime] = useState(null);

    useEffect(() => {
        const sorted = Object
            .keys(sectionalByNumP)
            .map(key => sectionalByNumP[key]?.STcle)
            .filter(stcle => !isNaN(stcle))
            .filter(stcle => stcle > 0)
            .sort((s1, s2) => s1 < s2 ? 1 : -1)
        setBestDoorTime(sorted.pop())
        setWorstDoorTime(sorted.shift());
    }, [sectionalByNumP]);

    return list.map((item, index) => (
        <RaceSpectatorPerformancesItem
            key={item.key}
            dataFull={item}
            dataSect={sectionalByNumP[item.NumP] || {}}
            bestDoorTime={bestDoorTime}
            worstDoorTime={worstDoorTime}
            index={index}
            />
    ));
}

function RaceSpectatorPerformancesWait() {
    return (
        <tr><td colSpan={9} className="cell-waiting">Waiting data</td></tr>
    );
}

export function RaceSpectatorPerformancesData({waitingData, dataFull, dataSect, distance}) {
    const [listFull, setListFull] = useState([]);
    const [sectionalByNumP, setSectionalByNumP] = useState({});
    const [perc, setPerc] = useState(null);
    const [previousDoor, setPreviousDoor] = useState(null);
    const [lastDoor, setLastDoor] = useState(null);

    useEffect(() => {
        const list = Object.keys(dataFull).map(key => dataFull[key]);
        const first = list.filter(item => item.PosC === 1).pop()
        setListFull(list);
        if (first && distance) {
            setPerc(Math.round(100 * first.DpDp / distance));
        } else {
            setPerc(null);
        }
    }, [dataFull, distance]);

    useEffect(() => {
        setSectionalByNumP(dataSect)
        setPreviousDoor(dataSect.previousDcle);
        setLastDoor(dataSect.nameDcle);
    }, [dataSect]);

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th className="competitors column-competitor" rowSpan="2"></th>
                        <th colSpan="4">CURRENT PERFORMENCES ({perc === null ? '-' : perc}% of the race)</th>
                        <th className="separate"></th>
                        <th colSpan="3">LAST SECTION ({previousDoor} - {lastDoor})</th>
                    </tr>
                    <tr>
                        <th className="column-rank">Rank</th>
                        <th className="column-speed">Speed<br />(km/h)</th>
                        <th className="column-tofirst">Dist to 1st<br />(m)</th>
                        <th className="column-dst">Total dist.<br />(m)</th>
                        <th className="separate"></th>
                        <th className="column-sect-rank">Diff rank</th>
                        <th className="column-sect-speed">Av. Speed<br />(km/h)</th>
                        <th className="column-sect-time">Time<br />(sec)</th>
                    </tr>
                </thead>
                <tbody>
                    { waitingData ? <RaceSpectatorPerformancesWait/> : <RaceSpectatorPerformancesList list={listFull} sectionalByNumP={sectionalByNumP}/> }
                </tbody>
            </table>
        </>
    )
}
