import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import UserService from "../services/user.service";
import SourceService from "../services/source.service";

import './privateUserForm.css';
import { Button, Input, Modal, Select, Switch, message } from "antd";

const RIGHTS = ['Classic', 'LiveFull', 'LiveSectional', 'PostFullRaw', 'PostFullMec', 'PostSectionalRaw', 'PostSectionalMec', 'Web'];

const YEARS = [];
for (let y=2018; y <= 2025; y++) {
    YEARS.push({value:y, label: y});
}
const MONTH = [
    { value:  1, label: 'Janvier' },
    { value:  2, label: 'Février' },
    { value:  3, label: 'Mars' },
    { value:  4, label: 'Avril' },
    { value:  5, label: 'Mai' },
    { value:  6, label: 'Juin' },
    { value:  7, label: 'Juillet' },
    { value:  8, label: 'Août' },
    { value:  9, label: 'Septembre' },
    { value: 10, label: 'Octobre' },
    { value: 11, label: 'Novembre' },
    { value: 12, label: 'Décembre' },
]
const DATES = [];
for (let d=1; d <= 31; d++) {
    DATES.push({value:d, label: d});
}

function isActual(start, stop) {
    if (!start) {
        return false;
    }
    if (!stop) {
        return false;
    }
    const today = new Date();
    const stringified = ''
        + today.getFullYear()
        + '-'
        + (today.getMonth() < 9 ? '0' : '')
        + (today.getMonth() + 1)
        + '-'
        + (today.getDate() < 10 ? '0' : '')
        + (today.getDate());
    return stringified >= start
        && stringified <= stop;
}

function TdRight({sourceUUID, item, change, enabled}) {

    const [isModalOpen, setOpenModal] = useState(false);
    const [isActivate, setActivate] = useState(false);
    const [start, setStart] = useState(null);
    const [stop, setStop] = useState(null);
    const [newStartYear, setNewStartYear] = useState(null);
    const [newStartMonth, setNewStartMonth] = useState(null);
    const [newStartDate, setNewStartDate] = useState(null);
    const [newStopYear, setNewStopYear] = useState(null);
    const [newStopMonth, setNewStopMonth] = useState(null);
    const [newStopDate, setNewStopDate] = useState(null);

    useEffect(() => {
        setActivate(!!item.start);
        setStart(item.start);
        setStop(item.stop);
        if (!!item.start && !!item.stop) {
            setNewStartYear(Number.parseInt(item.start.substring(0,4)));
            setNewStartMonth(Number.parseInt(item.start.substring(5,7)));
            setNewStartDate(Number.parseInt(item.start.substring(8,10)));
            setNewStopYear(Number.parseInt(item.stop.substring(0,4)));
            setNewStopMonth(Number.parseInt(item.stop.substring(5,7)));
            setNewStopDate(Number.parseInt(item.stop.substring(8,10)));
        } else {
            setNewStartYear(null);
            setNewStartMonth(null);
            setNewStartDate(null);
            setNewStopYear(null);
            setNewStopMonth(null);
            setNewStopDate(null);
        }
    }, [item])

    const changeActivate = () => {
        if (isActivate) {
            change(sourceUUID, {key: item.key, start: null, stop: null});
            setActivate(false);
        } else {
            setOpenModal(true);
        }
    }

    const handleOk = () => {
        const newStart = newStartYear
            + '-'
            + (newStartMonth < 10 ? '0' : '')
            + newStartMonth
            + '-'
            + (newStartDate < 10 ? '0' : '')
            + newStartDate;
        const newStop = newStopYear
            + '-'
            + (newStopMonth < 10 ? '0' : '')
            + newStopMonth
            + '-'
            + (newStopDate < 10 ? '0' : '')
            + newStopDate;
        change(sourceUUID, {
            key: item.key,
            start: newStart,
            stop: newStop,
        });
        setStart(newStart);
        setStop(newStop);
        setActivate(true);
        setOpenModal(false);
    }

    const handleCancel = () => {
        setOpenModal(false);
    }

    return (
        <td className={`user-source-input ${!enabled ? 'disabled' : isActivate ? 'activate' : 'unactivate'} ${isActual(start, stop) ? 'actual' : ''}`}>
            <div className="user-source-switch">
                Activé : <Switch checked={isActivate} onChange={() => changeActivate(sourceUUID, item)} disabled={!enabled}/>
            </div>
            { enabled ?
                <>
                    <div className="user-source-date-start">
                        {isActivate ?  (<>du {start}</>) : null }
                    </div>
                    <div className="user-source-date-stop">
                        {isActivate ?  (<>au {stop}</>) : null }
                    </div>
                    <Modal
                        title="Choix de la période d'activation"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        className="modal-period-choice"
                        >
                        <div className="period-choice">
                            <div className="date-choice period-start">
                                <div className="label">
                                    Start
                                </div>
                                <div className="date">
                                    <Select options={DATES} onChange={date => setNewStartDate(date)} value={newStartDate} popupClassName="date-choices-list" showSearch={true}/>
                                </div>
                                <div className="month">
                                    <Select options={MONTH} onChange={month => setNewStartMonth(month)} value={newStartMonth} popupClassName="date-choices-list" showSearch={true}/>
                                </div>
                                <div className="year">
                                    <Select options={YEARS} onChange={year => setNewStartYear(year)} value={newStartYear} popupClassName="date-choices-list" showSearch={true}/>
                                </div>
                            </div>
                            <div className="date-choice period-stop">
                                <div className="label">
                                    Stop
                                </div>
                                <div className="date">
                                    <Select options={DATES} onChange={date => setNewStopDate(date)} value={newStopDate} popupClassName="date-choices-list" showSearch={true}/>
                                </div>
                                <div className="month">
                                    <Select options={MONTH} onChange={month => setNewStopMonth(month)} value={newStopMonth} popupClassName="date-choices-list" showSearch={true}/>
                                </div>
                                <div className="year">
                                    <Select options={YEARS} onChange={year => setNewStopYear(year)} value={newStopYear} popupClassName="date-choices-list" showSearch={true}/>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
                : null
            }
        </td>
    );
}

export default function PrivateUserForm() {

    const { uuid } = useParams();
    const history = useHistory();

    const [title, setTitle] = useState();
    const [changePassword, setChangePassword] = useState(false);
    const [user, setUser] = useState({});
    const [sources, setSources] = useState([]);

    useEffect(() => {
        SourceService.list()
            .then(sources => {
                setSources(sources);
            }).catch(error => {
                console.log(error)
                message.error('Une erreur est survenue')
            })
    }, []);

    useEffect(() => {
        if (uuid) {
            Promise.resolve()
                .then(() => UserService.loadByUUID(uuid))
                .then(user => ({
                    uuid: user.uuid,
                    username: user.username,
                    email: user.email,
                }))
                .then(user => UserService.getRightsByUUID(user.uuid).then(rights => ({...user, rights})))
                .then(user => {
                    setTitle('Utilisateur #' + user.uuid);
                    setUser(user);
                })
                .catch(error => {
                    console.log(error);
                    message.error('Une erreur est survenue lors du chargement de l\'utilisateur');
                })
        } else {
            setTitle('Nouvel utilisateur');
            setUser({rights:[]});
            setChangePassword(true);
        }
    }, [uuid])

    const changeUserData = (user) => {
        setUser(user);
    }
    const changeActivation = (uuid, item) => {
        let right = user.rights.find(right => right.source === uuid);
        if (!right) {
            right = {
                source: sources.find(source => source.uuid === uuid).uuid,
            };
            RIGHTS.forEach(key => {
                right[`activation${key}Start`] = null;
                right[`activation${key}Stop`] = null;
            })
            user.rights.push(right);
        }
        right[`activation${item.key}Start`] = item.start;
        right[`activation${item.key}Stop`] = item.stop;
        setUser(user);
    }

    const onCancel = () => {
        history.push("/users");
    }

    const onFinish = () => {
        UserService
            .saveUserWithRights(user)
            .then(saved => {
                if (saved) {
                    message.success('Enregistrement effectué');
                    setChangePassword(false);
                    history.push(`/user/${saved.uuid}`)
                } else {
                    message.error('Une erreur est survenue lors de l\'enregistrement');
                }
            });
    }

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper user-page">
            <div className="header">
                <span className="title">{title}</span>
            </div>
            <div className="user-form">
                <div className="user-data-form">
                    <div className="user-data-input">
                        <div className="label">
                            Nom
                        </div>
                        <div className="input">
                            <Input
                                value={user.username}
                                onChange={event => changeUserData(({...user, username: event.target.value}))}
                                />
                        </div>
                    </div>
                    <div className="user-data-input">
                        <div className="label">
                            Email
                        </div>
                        <div className="input">
                            <Input
                                value={user.email}
                                onChange={event => changeUserData(({...user, email: event.target.value}))}
                                />
                        </div>
                    </div>
                    <div className="user-data-input">
                        <div className="label">
                            Mot de passe
                        </div>
                        <div className="input">
                            {
                                changePassword ? 
                                    <Input
                                        type="password"
                                        value={user.password}
                                        onChange={event => changeUserData(({...user, password: event.target.value}))}
                                        />
                                :
                                <Button onClick={() => setChangePassword(true)}>
                                    Changer le mot de passe
                                </Button>
                            }
                        </div>
                    </div>
                    {
                        changePassword ? 
                            <div className="user-data-input">
                                <div className="label">
                                    Confirmation
                                </div>
                                <div className="input">
                                    <Input
                                        type="password"
                                        value={user.confirm}
                                        onChange={event => changeUserData(({...user, confirm: event.target.value}))}
                                        />
                                </div>
                            </div>
                        : null
                    }
                </div>
                <div className="user-sources-form">
                    <table className="user-sources-table">
                        <thead>
                            <tr className="user-sources-header">
                                <td rowSpan={3}></td>
                                <td rowSpan={3} className="right-level-1">Classic</td>
                                <td colSpan={2} className="right-level-1">Live</td>
                                <td colSpan={4} className="right-level-1">Post</td>
                                <td rowSpan={3} className="right-level-1">Web</td>
                            </tr>
                            <tr className="user-sources-header">
                                {/* <td></td> */}
                                {/* <td></td> */}
                                <td rowSpan={2} className="right-level-2">Full</td>
                                <td rowSpan={2} className="right-level-2">Sectional</td>
                                <td colSpan={2} className="right-level-2">Full</td>
                                <td colSpan={2} className="right-level-2">Sectional</td>
                                {/* <td></td> */}
                            </tr>
                            <tr className="user-sources-header">
                                {/* <td></td> */}
                                {/* <td></td> */}
                                {/* <td></td> */}
                                {/* <td></td> */}
                                <td className="right-level-3">DataRaw</td>
                                <td className="right-level-3">MEC</td>
                                <td className="right-level-3">DataRaw</td>
                                <td className="right-level-3">MEC</td>
                                {/* <td></td> */}
                            </tr>
                        </thead>
                        <tbody>
                        { sources.map(source => {
                            const right = !user.rights ? {} : (user.rights.find(right => right.source === source.uuid) || {});
                            const list = RIGHTS.map(key => ({
                                endabledKey: `enabled${key}`,
                                start: right[`activation${key}Start`],
                                stop: right[`activation${key}Stop`],
                                key
                            }))
                            return (
                                <tr key={source.uuid} className="user-source-form">
                                    <td className="user-source-label">
                                        {source.label}
                                    </td>
                                    {list.map(item => (
                                        <TdRight key={item.key} sourceUUID={source.uuid} item={item} change={changeActivation} enabled={source[item.endabledKey]}/>
                                    ))}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="user-form-actions">
                    <div className="user-form-action">
                        <Button type="primary" htmlType="submit" onClick={() => onCancel()} className="user-form-button">
                            Retour
                        </Button>
                    </div>
                    <div className="user-form-action">
                        <Button type="primary" htmlType="submit" onClick={() => onFinish()} className="user-form-button">
                            Enregistrer
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}