import { Avatar, Table } from "antd";

export function RaceDataFinish({race_started, current_door, data}) {
    // columns_finish
    const columns = [
      {
        title: 'Rank',
        dataIndex: 'PosC',
        key: 'rank',
        ellipsis: true,
        className: 'sec-col-rank',
      },
      {
        title: '#',
        dataIndex: 'NumP',
        key: 'num',
        ellipsis: true,
        className: 'sec-col-num',
        render: (value, record) => {
          return <Avatar style={{ backgroundColor: `#${record.color}`, color: `#${record.font}`, border: `1px solid #${record.border}`, verticalAlign: 'middle' }} size={25}>
          {value || ''}
          </Avatar>
        },
      },
      {
        title: 'Time',
        dataIndex: 'HumanTcle',
        key: 'time',
        ellipsis: true,
        className: 'sec-col-time',
      },
      {
        title: 'Max Speed',
        dataIndex: 'Vmax',
        key: 'maxspeed',
        ellipsis: true,
        className: 'sec-col-maxspeed',
      },
    ];
  
    const list = Object
      .keys(data)
      .map(key => ({
        ...data[key],
        key,
      }))
      .sort((a, b) => a.PosC < b.PosC ? -1 : 1)
  
    return (
      <div className="sec_block_current">
        <div className={race_started ? "race_live_sectional live" : "race_live_sectional"}  >
          <span className="sec_block_pc_finish">
            {current_door}
          </span>
          <Table 
            columns={columns} 
            dataSource={list} 
            className="sec_block finish"
            pagination={false}
            tableLayout="fixed" 
          />
        </div>
      </div>
    );
  }
  
  