import React from "react";

export default function PrivateDashboard() {

  return (
    <div className="private_page_wrapper">
      <h1>Dashboard</h1>
    </div>
  )

}