import SocketIO  from 'socket.io-client';
import RaceLiveService from './race-live.service';
// En commentaire car : 1/ le debug n'a pas aller en prod (les dev decommenteront si ils veulent des simulation de courses) 2/ ça fait planter la prod, puisque /socket.io n'est pas sur la racine en prod
// import SocketDebugService from './socket.debug-service';

// ------------------------------------------------------------------
let socket = null;

const REACT_APP_LIVE_PROTOCOL = process.env.REACT_APP_LIVE_PROTOCOL;
const REACT_APP_LIVE_HOST = process.env.REACT_APP_LIVE_HOST;

// ------------------------------------------------------------------
function close() {
    console.log(`[NET] - Socket closing ....`);
    if (socket) {
        socket.close();
    }
}

// ------------------------------------------------------------------
function init(source) {
    // En commentaire car : 1/ le debug n'a pas aller en prod (les dev decommenteront si ils veulent des simulation de courses) 2/ ça fait planter la prod, puisque /socket.io n'est pas sur la racine en prod
    // if (SocketDebugService.DEBUG) {
    //     return SocketDebugService.init(source);
    // }

    const protocol = REACT_APP_LIVE_PROTOCOL;
    const host = REACT_APP_LIVE_HOST;
    
    close();

    socket = SocketIO.connect(`${protocol}://${host}`, {path: `/live/${source}`});

    console.log(`[NET] ${source} - Socket connecting`);

    socket.on('connect', () => {
        console.log(`[NET] ${source} - Socket connected`);
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.accessToken;
        socket.emit('authentication', {token});
        socket.on('authenticated', () => {
            console.log(`[NET] ${source} - User authenticated`);
            socket.on('message', trame => {
                RaceLiveService.updateTo(trame)
            });
        });
    });
  
    socket.on('unauthorized', (reason) => {
        console.log(`[NET] ${source} Unauthorized:`, reason);
        socket.disconnect();
    });
  
    socket.on('disconnect', (reason) => {
        console.log(`[NET] ${source} Disconnected:`, reason);
        socket.disconnect();
    });
  
    socket.on('close', () => {
        console.log(`[NET] ${source} - Client closed`);
        // setTimeout(() => {
        //     // TODO GBE : doit on reinit si on tombe ?
        //     init(source)
        // }, 500)
    });
  
    socket.on('error', (error) => {
        console.log(`[NET] ${source} - Client error`, error);
        // setTimeout(() => {
        //     // TODO GBE : doit on reinit si on tombe ?
        //     init(source)
        // }, 500)
    });

}

// ------------------------------------------------------------------
const SocketService = {
    init,
    close,
};
  
export default SocketService;