import React from "react";
import { Button, Upload, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

import '../styles/private_insert_races.css';
import meeting from "../services/meeting.service";

function InjectMeetingHeader() {
    return (
        <div className="header">
            <span className="title">Injection de réunion</span>
            <p>
                <span className="danger">ATTENTION</span>, cette page est en place pour <span className="information">Max R.</span> et <span className="information">Guillaume B.</span>
            </p>
            <p>
                <span className="danger">ATTENTION</span>, cette page va injecter un meeting "Manuel", donc dans le flux "Démo".<br />
                Il ne convient pas pour modifier ou ajouter une réunion sur un autre flux (france Galop ou autre).
            </p>
            <p>
                <span className="danger">ATTENTION</span>, il n'y a pas de vérification atre que le format.<br />
                Vérifier bien le fichier avant de l'envoyer : format du nom, format du contenu et valeur du contenu.
            </p>
            <p>
                <span className="information">INFO</span>: le fichier doit être au format JSON, avec l'architecture suivante.
            </p>
            <ol>
                <li>"Date": au format DD/MM/YYYY.</li>
                <li>"IDHippo": code de l'hippodrome (doit exister) sur 2 ou 3 caractères. Respecter les majuscules.</li>
                <li>"LibHippo": nom de l'hippodrome (optionel).</li>
                <li>"numR": numéro de la réunion (sans guillemet, c'est un nombre).</li>
                <li>"courses": liste de courses ayant le format :
                    <ol>
                        <li>"NumC": numéro de la course (sans guillemet, c'est un nombre).</li>
                        <li>"HeureC": heure de la course au format "HH:MM".</li>
                        <li>"LibC": libellé de la course.</li>
                        <li>"codeParcours": code du parcours (doit exister).</li>
                        <li>"NDPart": nombre de partants (ne sert pas aujourd'hui).</li>
                        <li>"Dist": Distance du parcours</li>
                        <li>"Disc": Discipline</li>
                        <li>"partants": liste des participants ayant le format :
                            <ol>
                                <li>"Num": Numéro du partant.</li>
                                <li>"EtatP": code état du partant : "DP" partant // "NP" non partant</li>
                                <li>"DistP": Distance pour le partant (égal à la distance de la course)</li>
                                <li>"NomC": Nom du cheval</li>
                                <li>"Jock": Nom du jockey</li>
                                <li>"Entr": Nom de l'entraineur</li>
                                <li>"Prop": Nom du prioriétaire</li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

function InjectMeetingForm() {
    const props = {
        customRequest: ({ onSuccess, onError, file}) => {
            meeting.injectFile(file)
                .then(() => onSuccess("Ok"))
                .catch(err => onError({ err }));
        },
        onChange({ file }) {
            if (file.status === 'done') {
                message.success(`Fichier ${file.name} correctement uploader. Il sera traité prochainement`);
            } else if (file.status === 'error') {
                message.error(`Erreur de upload du fichier ${file.name}.`);
            }
        },
    };
    return (
        <div className="body">
            <Upload {...props}>
                <Button icon={<CloudUploadOutlined />}>Upload</Button>
            </Upload>
        </div>
    );
}

export default function PrivateInjectMeeting() {
    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper inject-meeting-page">
            <InjectMeetingHeader />
            <InjectMeetingForm />
        </div>
    );
}