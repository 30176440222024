import { RaceInfo } from "./RaceInfo";
import { RaceProgress } from "./RaceProgress";
import { RaceTop5 } from "./RaceTop5";
import { NextRace } from "./NextRace";

export function RaceLiveOverview({started, ended, distance, type, finishers, inprogress, percent, next}) {
    const data = ended ? finishers : started ? inprogress : [];
    return (
        <div className="race_live_overview">
            <RaceInfo distance={distance} type={type}/>
            <RaceTop5 data={data} finish={ended}/>
            { ended ? <NextRace list={next} /> : <RaceProgress percent={percent}/> }
        </div>
    );
}
  
