import axios from "axios";
import authHeader from "./auth-header";

// list -------------------------------------------------------------
const list = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v2/sources',
        method: 'get',
        headers: authHeader(),
        params: {
                perPage: -1,
        },
    }).then(response => {
        if (response.status === 200) {
            return response.data.list;
        } else {
            return Promise.reject({
                status: response.status,
                statusText:response.statusText,
                data: response.data,
            })
        }
    })
}

// default export ---------------------------------------------------
const source = {
    list,
};

export default source;