import { Avatar, Table, Tooltip } from "antd";

export function RaceTop5({data, finish}) {
    const columns = [{
        title: 'Rank',
        dataIndex: 'PosC',
        key: 'rank',
        ellipsis: true,
        className: 'column-rank',
    }, {
        title: '#',
        dataIndex: 'NumP',
        key: 'num',
        ellipsis: true,
        className: 'column-runner-number',
        render: (value, record) => (
            <Avatar style={{ backgroundColor: `#${record.color}`, color: `#${record.font}`, border: `1px solid #${record.border}`, verticalAlign: 'middle' }} size={25} key={record.NumP}>
                {value || ''}
            </Avatar>
        ),
    }, {
        title: 'Runner',
        dataIndex: 'nomcomplet',
        key: 'nomcomplet',
        ellipsis: true,
        className: 'column-runner-name',
        render: (text, record) => (
            <Tooltip title={text} placement="bottom" overlayClassName="top5_tooltip" key={record.NumP}>
                {text}
            </Tooltip>
        ),
    }];
    if (finish) {
        columns.push({
            title: 'Time',
            dataIndex: 'HumanTcle',
            key: 'time',
            ellipsis: true,
            className: 'column-time',
        }, {
            title: 'Max Speed',
            dataIndex: 'Vmax',
            key: 'maxspeed',
            ellipsis: true,
            className: 'column-maxspeed',
        });
    } else {
        columns.push({
            title: 'Dist/1st',
            dataIndex: 'Eprm',
            key: 'distfirst',
            ellipsis: true,
            className: 'column-tofirst',
        }, {
            title: 'Inst. Speed',
            dataIndex: 'Vit',
            key: 'instspeed',
            ellipsis: true,
            className: 'column-time',
        });
    }

    return (
        <div className="race_ranking_top5">
            <span className="title">TOP 5</span>
            <Table 
                columns={columns}
                dataSource={data} 
                className="table" 
                pagination={false}
                tableLayout="fixed" 
                />
        </div>
    )
}

  