// import _ from "lodash";
import moment  from 'moment-timezone';

import live from "../services/live.service";

const CET_TZ = 'Europe/Paris';

const NOW_DELAY = 10;
const NEAR_DELAY = 60;
const LATER_DELAY = null;

function getColor(horse){
    const colors = [{
        color: 'ff557b',
        border: 'ff557b',
        font: 'ffffff',
    }, {
        color: 'ffffff',
        border: '373633',
        font: '373633',
    }, {
        color: '0084c9',
        border: 'ffffff',
        font: 'ffffff',
    }, {
        color: 'f7fc0f',
        border: '373633',
        font: '373633',
    }, {
        color: '4b7f56',
        border: 'ffffff',
        font: 'ffffff',
    }, {
        color: '3f3e36',
        border: 'ffffff',
        font: 'f3fc57',
    }, {
        color: 'ff6e6e',
        border: 'ffffff',
        font: '443029',
    }, {
        color: 'fc6d87',
        border: 'ffffff',
        font: '443029',
    }, {
        color: '008eba',
        border: 'ffffff',
        font: '443029',
    }, {
        color: '8669a5',
        border: 'ffffff',
        font: 'ffffff',
    }, {
        color: 'd7d4c7',
        border: 'ffffff',
        font: 'e77787',
    }, {
        color: '4ebf5f',
        border: 'ffffff',
        font: '2b3d20',
    }, {
        color: '624742',
        border: 'ffffff',
        font: 'ffffff',
    }, {
        color: '7c4c57',
        border: 'ffffff',
        font: 'fcf84d',
    }, {
        color: 'ada68c',
        border: 'ffffff',
        font: '3e362d',
    }, {
        color: '8febee',
        border: 'ffffff',
        font: 'fc6082',
    }, {
        color: '4a585f',
        border: 'ffffff',
        font: 'ffffff',
    }, {
        color: '454d47',
        border: 'ffffff',
        font: 'f3fb48',
    }, {
        color: '56637d',
        border: 'ffffff',
        font: 'e8638a',
    }, {
        color: 'fe607d',
        border: 'ffffff',
        font: 'fedc66',
    }, {
        color: 'a7a1c3',
        border: 'ffffff',
        font: '494d5b',
    }];
    const number = Number(horse);
    if (isNaN(number)) {
        return colors[0];
    } else {
        return colors[number % colors.length];
    }
}

export class RaceHandler {
    constructor() {
        this._meetingList = [];
        this._horsesInfos = [];
        this._horsesByNum = {};
        this._currentRace = null;
        // this._nextRace = null;
        this._raceEnd = false;
    }

    async init(raceDate, raceMeeting, raceNumber) {
        function isTheRace(race) {
            return race.date ===  raceDate
                && race.numr === raceMeeting
                && race.numc === raceNumber;
        }
        return Promise.resolve()
            // Calculate meeting list
            .then(() => live.meeting_list({date: raceDate.split('-').join('')}))
            .then(list => list.sort((a,b) => a.heurec < b.heurec ? -1 : 1))
            .then(list => list.map(race => ({date: raceDate, ...race})))
            .then(list => list.map(race => ({dateBlock: race.date.split('-').join(''), ...race})))
            .then(list => this._meetingList = list)
            // Calculate current race
            .then(() => this._currentRace = this._meetingList.find(race => isTheRace(race)))
            // Calculate current race runners
            .then(() => live.runners_details({
                date: this._currentRace.dateBlock,
                numr: this._currentRace.numr,
                numc: this._currentRace.numc,
            }))
            .then(details => this._horsesInfos = details)
            .then(() => this.recalculateHorsesMapping())
        }

    isDateOfRace(date, numc, numr){
        return this._currentRace
            && this._currentRace.dateBlock === date
            && this._currentRace.numr === numr
            && this._currentRace.numc === numc
    }

    recalculateHorsesMapping() {
        this._horsesByNum = this._horsesInfos.reduce((mapped, horse) => {
            mapped[horse.num] = {
                ...horse,
                nomcomplet: `${horse.nomc} (${horse.jock})`,
                ...getColor(horse.num)
            };
            return mapped;
        }, {})
    }

    get_moment_in_cet_tz() {
        return moment().tz(CET_TZ);
    }

    getHorsesInfos() {
        return this._horsesInfos;
    }

    getHorsesInfosCompleted(captures) {
        return captures.map(capture => {
            const detail = this._horsesByNum[capture.NumP];
            return {
                ...capture,
                nomcomplet: detail ? detail.nomcomplet : capture.nomc,
                color: detail ? detail.color : null,
                border: detail ? detail.border : null,
                font: detail ? detail.font : null,
            }
        });
    }

    howMinutesToStart(heurec) {
        let h_curr = this.get_moment_in_cet_tz().hour();
        let m_curr = this.get_moment_in_cet_tz().minute();
        let c = moment(h_curr +':'+ m_curr, 'HH:mm');
        let r = moment(heurec, 'HH:mm');
        return moment.duration(r.diff(c)).asMinutes();
    }
    
    getNextRaces() {
        const listNow = []
        const listNear = []
        const listLater = []
        this._meetingList
            .filter(race => race !== this._currentRace)
            .map(race => ({
                ...race,
                startIn: this.howMinutesToStart(race.heurec),
            }))
            .filter(race => race.startIn > 0) // On ne veux pas les courses passées
            .forEach(race => {
                if (!NOW_DELAY || race.startIn < NOW_DELAY) {
                    listNow.push(race);
                } else if (!NEAR_DELAY || race.startIn < NEAR_DELAY) {
                    listNear.push(race);
                } else if (!LATER_DELAY || race.startIn < LATER_DELAY) {
                    listLater.push(race);
                }
            })
        return {
            now: listNow,
            near: listNear,
            later: listLater,

        };
    }

    getCurrentRace() {
        return this._currentRace;
    }

    getCurrentDistance() {
        if (this._currentRace) {
            return Number(this._currentRace.dist);
        } else {
            return Number.NaN;
        }
    }
}