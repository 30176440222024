import { RaceDataCurrent } from "./RaceDataCurrent";
import { RaceDataFinish } from "./RaceDataFinish";
import { RaceDataSectional } from "./RaceDataSectional";
import { CountDownToRace } from "./CountDownToRace";

export function RaceLiveBody({race_started, race_ended, heurec, doors, current_door, finish_block, current_block}) {
    return (
        <div className="race_live_body">
            <h1 className="race_live_title">LIVE SECTIONS</h1>
            <div className="race_live_body_wrapper">
                {race_started ? 
                    <>
                        <div className="race_live_sectionals">
                            {Object.keys(doors)
                                .filter(door => (door !== 'PCDEP') && (door !== 'PCARR'))
                                .map(door => <RaceDataSectional door={door} data={doors[door]} key={door} />)
                            }
                        </div>
                        { race_ended ? 
                            <RaceDataFinish
                                race_started={race_started}
                                current_door={current_door}
                                data={finish_block}
                                />
                        :
                            <RaceDataCurrent
                                race_started={race_started}
                                current_door={current_door}
                                data={current_block}
                                />
                        }
                    </>
                : 
                    <div className="countdown_block">
                        {heurec ? <CountDownToRace heurec={heurec}/> : null}
                    </div>
                }
            </div>
        </div>
    );
}
