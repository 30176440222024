import {
    useEffect,
    useState,
} from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/loader"
import { RaceSpectator } from "../components/spectator/RaceSpectator";

import RaceLiveService from "../services/race-live.service";
import SocketService from "../services/socket.service";

const moment = require('moment-timezone');
const CET_TZ = 'Europe/Paris';


export default function Spectator() {
    const {source, RxCx} =  useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const [,race_meeting, race_number] = RxCx.match(/^R([0-9]*)C([0-9]*)$/);
        const race_date = moment().tz(CET_TZ).format('Y-MM-DD');
        Promise.resolve()
          .then(() => RaceLiveService.init(race_date, race_meeting, race_number))
          .then(() => SocketService.init(source))
          .then(() => setLoading(false))
      }, [source, RxCx]);
    

    // render ---------------------------------------------------------
    return (
        <div id="spectator_wrapper" className="spectator_wrapper">
            { loading ? 
                <Loader />
            : (
                <RaceSpectator />
            )}
        </div>
    )
}
