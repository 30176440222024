import React, {useState, useEffect} from "react";
import { DatePicker, Popover } from "antd";
import { 
    ClockCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import live from "../services/live.service";

const moment = require('moment-timezone');
const hour_range = [9,22]
const header_height = 60 + 50 + 20;
const h_height = 60;
const dateFormat = 'DD/MM/YYYY';

const CET_SUFFIX = ' CET';
const CET_TZ = 'Europe/Paris';

/*
  ++ pas de ligne rouge quand exterieur au range
  ++ info fin de course
*/

function Race({race, active, target}) {

    const history = useHistory();

    const [link, setLink] = useState('');
    const [offset, set_offset] = useState('');
    const [rc, set_rc] = useState('');

    useEffect(() => {
        const [hours, minutes] = race.heurec.split(':');
        set_offset(header_height + h_height * (Number(hours) - hour_range[0]) + Number(minutes));
        set_rc(`R${race.numr}C${race.numc}`);
    }, [race]);

    useEffect(() => {
        setLink(target.replace('%SOURCE%', race.source).replace('%RxCx%', rc))
    }, [target, race, rc]);

    // get_popover_title ----------------------------------------------
    const get_popover_title = (race) => {
        return (
            <div className="popover_title_wrapper">
                <span className="popover_race_number">{`R${race.numr}C${race.numc}`}</span>
                <span className="popover_hippo">{race.hippodrome}</span>
            </div>
        )
    }

    // get_popover_content --------------------------------------------
    const get_popover_content = (race) => {
        return (
            <div className="popover_content_wrapper">
                <p className="race_name">{race.libc}</p>
                <p>
                    <span className="p_icon"><ClockCircleOutlined /></span>
                    <span className="p_label">Start:</span>
                    <span className="p_val">{race.heurec}{CET_SUFFIX}</span>
                </p>
                <p>
                    <span className="p_icon"><img src={`${process.env.PUBLIC_URL}/images/icons/horse.png`} alt="Runners" /></span>
                    <span className="p_label">Runners:</span>
                    <span className="p_val">{race.ndpart}</span>
                </p>
                <p>
                    <span className="p_icon"><img src={`${process.env.PUBLIC_URL}/images/icons/sulky.png`} alt="Discipline" /></span>
                    <span className="p_label">Discipline:</span>
                    <span className="p_val">{race.disc}</span>
                </p>
                <p>
                    <span className="p_icon"><img src={`${process.env.PUBLIC_URL}/images/icons/hippodrome.png`} alt="Distance" /></span>
                    <span className="p_label">Distance:</span>
                    <span className="p_val">{race.dist}</span>
                </p>
            </div>
        )
    }

    return (
        <Popover
            key={`popover_${rc}`}
            placement="right"
            title={get_popover_title(race)}
            content={get_popover_content(race)}
            trigger="hover">
            <div key={`block_${rc}`} 
                className={active === rc ? 'race_block active' : 'race_block'} 
                style={{top: `${offset}px`}}
                onClick={() => history.push(link)}
                >
                {rc}
            </div>
        </Popover>
    );
}

function MeetingsTitles({meetings}) {
    return meetings
        .map(meeting =>
            <div key={`meeting_${meeting.meetingKey}`} className="item">
                <span>Meeting {meeting.numr}</span>
                <span>{meeting.hippodrome}</span>
            </div>
        );
}

function MeetingsContents({meetings, races, active, target}) {
    return meetings.map(meeting => 
        <div key={`race_timeline_meeting_${meeting.meetingKey}`} className="race_timeline_meeting">
            {races
                .filter(race => meeting.meetingKey === race.meetingKey)
                .map(race => <Race key={race.raceKey} race={race} active={active} target={target}/>)}
        </div>
    );
}

export default function RaceCalendar({target}) {
    const [loading, set_loading] = useState(false);
    const [current_date, set_current_date] = useState(get_moment_in_cet_tz());
    const [activeRaceCode, setActiveRaceCode] = useState("");
    const [current_race_title, set_current_race_title] = useState("No race for the moment");
    const [meetings, setMeetings] = useState([]);
    const [races, setRaces] = useState([]);
    const [time_line_offset, set_time_line_offset] = useState();
    const [h_curr, set_h_curr] = useState(0);
    const [m_curr, set_m_curr] = useState(0);
    const [matrix, set_matrix] = useState([]);

    function get_moment_in_cet_tz() {
        return moment().tz(CET_TZ);
    }

    function get_range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    function format_label_hour(h) {
        return (String(h).length < 2) ? `0${h}` : h;
    }

    function refresh_time_line_offset() {
        let h_start = hour_range[0];
        let h_curr = get_moment_in_cet_tz().hour();
        let m_curr = get_moment_in_cet_tz().minute();

        set_h_curr(h_curr);
        set_m_curr(m_curr);
        let offset = header_height + (h_height * (h_curr - h_start)) + m_curr;
        set_time_line_offset({top: `${offset}px`});
    }

    function datepicker_handler(date) {
        set_current_date(date);
    }

    const is_line_in_range = () => {
        let c_date = get_moment_in_cet_tz();
        return !(h_curr < hour_range[0]
            || h_curr > hour_range[1]
            || (current_date.format('YMMDD') !== moment(c_date).format('YMMDD')));

    }

    // set active_race ------------------------------------------------
    const active_race = () => {
        let c_date = get_moment_in_cet_tz();
        let found = false;

        for (let race of races) {
            let r = moment(race.heurec, 'HH:mm');
            let c = moment(h_curr +':'+ m_curr, 'HH:mm');
            let diff = moment.duration(r.diff(c)).asMinutes();
            if (current_date.format('YMMDD') === moment(c_date).format('YMMDD')
            && (diff < 20) && (diff > -20) && (r <= c)) {
            found = true;
            set_current_race_title(
                `${race.heurec}${CET_SUFFIX}`
                + ` - CURRENT : MEETING ${race.numr}`
                + ` : ${race.hippodrome}`
                + ` / RACE ${race.numc}`
                + ` - ${race.libc}`
            );
            setActiveRaceCode(`R${race.numr}C${race.numc}`);
            break;
            }
        }

        if (!found) {
            set_current_race_title('No active race');
            setActiveRaceCode("");
        }
    }

    // refresh_meetings -----------------------------------------------
    const refresh_meetings = async () => {
        live.meeting_list({date: current_date.format('YMMDD')})
            .then(list => {
                const races = list
                    .map(race => ({
                    ...race,
                    raceKey: race.source + '#' + race.numr + '#' + race.numc,
                    meetingKey: race.source + '#' + race.numr,
                }))
                setRaces(races);
                const maps = races
                    .reduce((map, race) => {
                    map[race.meetingKey] = {
                        numr: race.numr,
                        hippodrome: race.hippodrome,
                        meetingKey: race.meetingKey,
                    }
                    return map;
                }, {})
                const meetings = Object.keys(maps).map(key => maps[key])
                setMeetings(meetings);
            })
    }

    // useEffect ------------------------------------------------------
    
    // first load
    useEffect(() => {
        set_loading(true);
        let range = get_range(hour_range[0], hour_range[1]);
        set_matrix(range);
        refresh_time_line_offset();
        set_loading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // interval every second to make the red line move
    useEffect(() => {
        const interval = setInterval(() => {
            refresh_time_line_offset();
            active_race();
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetings, races]);

    // when meeting list change
    useEffect(() => {    
        active_race();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetings, races]);

    // when current_date change
    useEffect(() => {
        set_loading(true);
        refresh_meetings().then(() => set_loading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_date])

    
    // render ---------------------------------------------------------
    return (
        <>
            { loading ? 
                <p>loading</p> 
            : (
                <div id="race_timeline_wrapper" className="race_timeline_wrapper">
                    
                    <div className="race_timeline_header">
                        <h1>{current_race_title}</h1>
                        <div className="race_timeline_header_clock">
                            <DatePicker defaultValue={current_date} onChange={datepicker_handler} format={dateFormat} />
                            <span>{`${format_label_hour(h_curr)}:${format_label_hour(m_curr)}${CET_SUFFIX}`}</span>
                        </div>
                    </div>

                    <div className="race_timeline_header_meeting">
                        <MeetingsTitles meetings={meetings}/>
                    </div>

                    <div id="race_timeline_content" className="race_timeline_content">
                        {is_line_in_range() && <div className="time_line" style={time_line_offset}/>}
                        <div className="race_timeline_hours">
                            {matrix.map(hour=> <div key={`matrix_idh_${hour}`} className="hour_label">{`${format_label_hour(hour)}H`}</div>)}
                        </div>
                        <MeetingsContents meetings={meetings} races={races} active={activeRaceCode} target={target}/>
                    </div>
                </div>
            )}
        </>
    )
}