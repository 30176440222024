export function RaceProgress({percent}) {
    return (
      <div className="race_progression">
        <div className="race_progression_wrapper">
          <span className="title">RACETRACK</span>
          <span className="value">{`${percent} %`}</span>
        </div>
      </div>
    );
  }
  
  