import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ProvideAuth } from "./services/auth.service";
import App from './App';

ReactDOM.render(
    <ProvideAuth>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ProvideAuth>
  ,
  document.getElementById('root')
);
