export function RaceLiveFooter() {
    return (
        <>
            {/* <div className="race_live_footer"> */}
                {/* <GetLatencyBloc latency_mode={latency_mode} latency={latency}/> */}
                {/* <div className="next_race_wrapper">{next_race}</div> */}
            {/* </div> */}
        </>
    );
}
