import { useEffect, useState } from "react";
import { CaretLeftOutlined, CaretRightOutlined, FlagOutlined } from "@ant-design/icons";

function format(value, decimal) {
    let str = '' + value;
    let index = str.indexOf('.');
    if (index===-1) {
        str += '.';
        index = str.indexOf('.');
    }
    str += '00000000000000';
    return str.substring(0, index + 1 + decimal);
}

function isDelta1(vit1, vit2, steps) {
    return vit1 - vit2 < steps.value1;
}

function isDelta2(vit1, vit2, steps) {
    return vit1 - vit2 >= steps.value1
        && vit1 - vit2 < steps.value2;
}

function isDelta3(vit1, vit2, steps) {
    return vit1 - vit2 >= steps.value2;
}

function whichDelta(vit1, vit2, steps) {
    return isDelta1(vit1, vit2, steps) ? 1
        : isDelta2(vit1, vit2, steps) ? 2
        : isDelta3(vit1, vit2, steps) ? 3
        : 0;
}

function RaceSpectatorDistanceToFirstTitle({steps, onUpdate}) {
    const [value1, setValue1] = useState(steps.value1);
    const [value2, setValue2] = useState(steps.value2);

    useEffect(() => {
        setValue1(steps.value1);
        setValue2(steps.value2);
    }, [steps]);

    function updateValue1(v) {
        if (v && !isNaN(v)) {
            setValue1(v);
            onUpdate({value1: v, value2});
        }
    }

    function updateValue2(v) {
        if (v && !isNaN(v)) {
            setValue2(v);
            onUpdate({value1, value2: v});
        }
    }

    return (
        <div className="race_spectator_dtf_herd_title_line">
            <span className="title">
                Speed difference with leader (average over last 1 second)
            </span>
            <span className="legend">
                <span className="item">
                    <span className="speed-delta-1"><CaretRightOutlined /></span>
                    from 0 to <input value={value1} onChange={event => updateValue1(event.target.value)}/> km/h
                </span>
                <span className="item">
                    <span className="speed-delta-2"><CaretRightOutlined /></span>
                    from {value1} to <input value={value2} onChange={event => updateValue2(event.target.value)}/> km/h
                </span>
                <span className="item">
                    <span className="speed-delta-3"><CaretRightOutlined /></span>
                    more than {value2} km/h
                </span>
            </span>
        </div>
    );
}


function RaceSpectatorDistanceToFirstLineDisqualified({sizing, font, color, border, NumP}) {
    return (
        <div
            style={{
                display: 'inline-block',
                width: `${sizing?.ITEM}px`,
                borderRadius: '12px',
                height: '26px',
                margin: '5px 0',
                position: 'absolute',
                padding: '0px 5px',
                left: `0px`,
                opacity: 0.2,
                }}
            >
            <span style={{display: 'inline-block', width: '70px'}}></span>
            <span style={{display: 'inline-block', width: '20px'}}></span>
            <span className={`speed-delta-0`}></span>
            <span
                className="competitor-jersey"
                style={{
                    color: '#' + font,
                    backgroundColor: '#' + color,
                    borderColor: border,
                }}
                >
                {NumP}
            </span>
            <span className={`speed-delta-0`}></span>
        </div>
    )
}

function RaceSpectatorDistanceToFirstLineLostData({sizing, font, color, border, NumP}) {
    return (
        <div></div>
    );
    // return (
    //     <div
    //         style={{
    //             display: 'inline-block',
    //             width: `100%`,
    //             height: '26px',
    //             position: 'absolute',
    //             padding: '0px 5px',
    //             textAlign: 'center',
    //             // backgroundColor: '#444',
    //             }}
    //         >
    //         <span
    //             className="competitor-jersey"
    //             style={{
    //                 color: '#' + font,
    //                 backgroundColor: '#' + color,
    //                 borderColor: border,
    //             }}
    //             >
    //             {NumP}
    //         </span>
    //         Lost data 
    //     </div>
    // )
}

function RaceSpectatorDistanceToFirstLine({data, first, steps, sizing}) {

    const [font, setFont] = useState('black');
    const [color, setColor] = useState('white');
    const [border, setBorder] = useState('white');
    const [NumP, setNumP] = useState(0);
    const [PosC, setPosC] = useState(0);
    const [Eprm, setEprm] = useState(0);
    const [position, setPosition] = useState(0);
    const [deltaPositif, setDeltaPositif] = useState(0);
    const [deltaNegatif, setDeltaNegatif] = useState(0);
    const [disqualified, setDisqualified] = useState(false);
    const [lostData, setLostData] = useState(false);

    useEffect(() => {
        setFont(data.font);
        setColor(data.color);
        setBorder(data.border);
        setNumP(data.NumP);
        setPosC(data.PosC);
        setEprm(data.Eprm);
        setDisqualified(false /* data.Cpar !== 'DP'*/ );
        setLostData(data.PosC === 0);
    }, [data]);

    useEffect(() => {
        const pct = Math.min(100, 5 * Math.abs(data.Eprm)) / 100
        if (sizing) {
            setPosition( Math.min(pct * sizing.HERD, sizing.HERD - sizing.ITEM) - sizing.TO_STICK_FINAL );
        } else {
            setPosition( 0 );
        }
    }, [data, sizing]);

    useEffect(() => {
        if (first) {
            if (first.Vit === data.Vit) {
                setDeltaPositif(0);
                setDeltaNegatif(0);
            } else if (first.Vit > data.Vit) {
                setDeltaPositif(0);
                setDeltaNegatif(whichDelta(first.Vit, data.Vit, steps));
            } else {
                setDeltaPositif(whichDelta(data.Vit, first.Vit, steps));
                setDeltaNegatif(0);
            }
        }
    }, [data, first, steps]);

    return (
        <div className="race_spectator_dtf_herd_horse_line" style={{width: `${sizing?.HERD}px`, height: '36px'}}>
            <div style={{position: 'relative'}}>
                {   disqualified ? <RaceSpectatorDistanceToFirstLineDisqualified sizing={sizing} font={font} color={color} border={border} NumP={NumP}/> :
                    lostData ? <RaceSpectatorDistanceToFirstLineLostData  sizing={sizing} font={font} color={color} border={border} NumP={NumP} /> :
                    <div
                        style={{
                            display: 'inline-block',
                            width: `${sizing?.ITEM}px`,
                            // textAlign: 'right',
                            // backgroundColor: 'gold',
                            borderRadius: '12px',
                            height: '26px', margin: '5px 0',
                            position: 'absolute',
                            padding: '0px 5px',
                            right: `${position}px`,
                            }}
                        >
                        <span style={{display: 'inline-block', width: '70px'}}>{format(Eprm, 1)} m</span>
                        <span style={{display: 'inline-block', width: '20px', textAlign: 'center'}}>({PosC})</span>
                        <span className={`speed-delta-${deltaNegatif}`}><CaretLeftOutlined /></span>
                        <span
                            className="competitor-jersey"
                            style={{
                                color: '#' + font,
                                backgroundColor: '#' + color,
                                borderColor: border,
                            }}
                            >
                            {NumP}
                        </span>
                        <span className={`speed-delta-${deltaPositif}`}><CaretRightOutlined /></span>
                    </div>
                }
            </div>
        </div>
    );
}

function RaceSpectatorDistanceToFirstLines({list, first, steps, sizing}) {
    return (
        <div className="race_spectator_dtf_herd_lines">
            <div className="race_spectator_dtf_herd_horse_lines" style={{width: sizing?.HERD}}>
                {list.map(item => (<RaceSpectatorDistanceToFirstLine key={item.key} data={item} first={first} steps={steps} sizing={sizing}/>))}
            </div>
            <div className="race_spectator_dtf_herd_final" style={{width: sizing?.FINAL}}></div>
        </div>
    );
}

function RaceSpectatorProgress({distance, total}) {
    const [pct, setPct] = useState(null);

    useEffect(() => {
        if (distance && total && total > 0) {
            setPct(100 * distance / total );
        } else {
            setPct(null);
        }
    }, [distance, total])

    return (
        <div className="race_spectator_progress">
            <div className="race_spectator_progress_pct">
                {pct ? Math.round(pct) : '-'}%
            </div>
            <div className="race_spectator_progress_bar">
                <div className="race_spectator_progress_bar_content" style={{width: `${pct}%`}}>&nbsp;</div>
            </div>
            <div className="race_spectator_progress_flag_container">
                <FlagOutlined className="race_spectator_progress_flag" />
            </div>
        </div>
    )
}

function RaceSpectatorDistanceToFirstWait({sizing}) {
    return (
        <div className="race_spectator_dtf_herd_lines">
            <div className="race_spectator_dtf_herd_horse_lines" style={{width: sizing?.HERD}}>
                <div className="race_spectator_dtf_herd_horse_line waiting" style={{width: `${sizing?.HERD}px`}}>
                    Waiting data
                </div>
            </div>
            <div className="race_spectator_dtf_herd_final" style={{width: sizing?.FINAL}}></div>
        </div>
    );
}

export function RaceSpectatorDistanceToFirst({waitingData, sizing, data, distance}) {
    const [steps, setSteps] = useState({value1: 1, value2: 4});
    const [list, setList] = useState([]);
    const [first, setFirst] = useState(null);
    
    useEffect(() => {
        const _list = Object.keys(data).map(key => data[key]);
        const _first = _list.filter(item => item.PosC === 1).pop();
        setFirst(_first);
        setList(_list);
    }, [data])
    
    return (
        <div className="race_spectator_distance_to_first">
            <div>
                <h1 className="race_spectator_subtitle">Distance to first</h1>
            </div>
            <div>
                <div className="race_spectator_dtf_block race_spectator_dtf_herd" style={{width: '100%'}}>
                    <RaceSpectatorDistanceToFirstTitle steps={steps} onUpdate={_steps => setSteps(_steps)}/>
                    { waitingData
                        ? <RaceSpectatorDistanceToFirstWait sizing={sizing}/>
                        : <RaceSpectatorDistanceToFirstLines list={list} first={first} steps={steps} sizing={sizing}/>
                    }
                </div>
                <div className="race_spectator_dtf_block race_spectator_dtf_progress">
                    <RaceSpectatorProgress distance={first?.DpDp} total={distance} />
                </div>
            </div>
        </div>
    )
}
