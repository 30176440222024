import { useState, useEffect, useRef } from "react";

import { Switch } from "antd";

import RaceLiveService from "../../services/race-live.service";

import { RaceSpectatorHeader } from "./RaceSpectatorHeader";
import { RaceSpectatorPerformances } from "./RaceSpectatorPerformances";
import { RaceSpectatorDistanceToFirst } from "./RaceSpectatorDistanceToFirst";
// import { RaceSpectatorSectionals } from "./RaceSpectatorSectionals";
import { RaceSpectatorRanking } from "./RaceSpectatorRanking";
import { RaceSpectatorChart } from "./RaceSpectatorChart";

export function RaceSpectator(_props, _ref){
    const [heurec, setHeurec] = useState(null);
    const [hippodrome, setHippodrome] = useState(null);
    const [numc, setNumc] = useState(null);
    const [numr, setNumr] = useState(null);
    const [libc, setLibc] = useState(null);
    const [prefix, setPrefix] = useState(null);
    const [raceDist, setRaceDist] = useState(null);
    const [raceType, setRaceType] = useState(null);
    const [dataFull, setDataFull] = useState({});
    const [dataSect, setDataSect] = useState({});
    const [dataSects, setDataSects] = useState({});

    const [colors, setColors] = useState({});
    const [speeds, setSpeeds] = useState([]);

    const [hasData, setHasData] = useState(false);

    const [displayPerformances, setDisplayPerformances] = useState(true);
    const [displayRanking, setDisplayRanking] = useState(true);
    const [displayDistanceToFirst, setDisplayDistanceToFirst] = useState(true);
    const [displayChart, setDisplayChart] = useState(true);

    const [sizing, setSizing] = useState(null);

    const ref = useRef(null);

    useEffect(() => {
        const TOTAL = ref.current.clientWidth - 100 - 20; // 100px => Percent zone // 2x10px => padding
        setSizing({
            FINAL: 10,
            HERD: TOTAL - 10,
            ITEM: 160,
            TO_STICK_FINAL: 25,
        });
    }, [dataFull]);
    
    useState(() => {
        RaceLiveService.callBackOnUpdate(updates => {
            const keys = Object.keys(updates);
            if (keys.length > 0) {
                if (updates.reinit) {
                    setHeurec(null);
                    setHippodrome(null);
                    setNumc(null);
                    setNumr(null);
                    setLibc(null);
                    setPrefix(null);
                    setRaceDist(null);
                    setDataFull({});
                    setHasData(false);
                    setColors({});
                    setSpeeds({});
                }
    
                if (updates.numc !== undefined) {setNumc(updates.numc);}
                if (updates.numr !== undefined) {setNumr(updates.numr);}
                if (updates.dist !== undefined) {setRaceDist(updates.dist);}
                if (updates.hippodrome !== undefined) {setHippodrome(updates.hippodrome);}
                if (updates.heurec !== undefined) {setHeurec(updates.heurec);}
                if (updates.libc !== undefined) {setLibc(updates.libc);}
                if (updates.prefix !== undefined) {setPrefix(updates.prefix);}
                if (updates.details !== undefined && updates.details.length > 0) {setRaceType(updates.details[0].disc);}
        
                if (updates.captures !== undefined) {
                    const _data = {};
                    const _colors = {};
                    setHasData(true);
                    updates.captures
                        .forEach(capture => {
                            _data[capture.NumP] = {
                                ...capture,
                                key: capture.NumP,
                            };
                            _colors[capture.NumP] = {
                                fill: capture.color,
                                border: capture.border,
                            }
                        });
                    setDataFull(_data);
                    setColors(_colors);
                }

                if (updates.sectional !== undefined) {
                    setHasData(true);
                    setDataSect(updates.sectional);
                }

                if (updates.sectionals !== undefined) {
                    setHasData(true);
                    setDataSects(updates.sectionals);
                }

                if (updates.speeds !== undefined) {
                    setHasData(true);
                    setSpeeds(updates.speeds);
                }
            }
        });
    });

    return (
        <div className="race_spectator_component" ref={ref}>
            <div className="race_spectator_component__switchers">
                <Switch style={{backgroundColor: displayPerformances ? 'green' : 'orange'}} checked={displayPerformances} onChange={() => {setDisplayPerformances(!displayPerformances)}}/>
                <span className="race_spectator_component__switcher_label">
                    Performances
                </span>
                <Switch style={{backgroundColor: displayDistanceToFirst ? 'green' : 'orange'}} checked={displayDistanceToFirst} onChange={() => {setDisplayDistanceToFirst(!displayDistanceToFirst)}}/>
                <span className="race_spectator_component__switcher_label">Distance to first</span>
                <Switch style={{backgroundColor: displayRanking ? 'green' : 'orange'}} checked={displayRanking} onChange={() => {setDisplayRanking(!displayRanking)}}/>
                <span className="race_spectator_component__switcher_label">Ranking</span>
                <Switch style={{backgroundColor: displayChart ? 'green' : 'orange'}} checked={displayChart} onChange={() => {setDisplayChart(!displayChart)}}/>
                <span className="race_spectator_component__switcher_label">Chart</span>
            </div>
            <RaceSpectatorHeader waitingData={!hasData} heure={heurec} prefix={prefix} meeting={numr} race={numc} venue={hippodrome} label={libc} distance={raceDist} discipline={raceType}/>
            <span style={{display: displayPerformances ? 'block' : 'none'}}>
                <RaceSpectatorPerformances waitingData={!hasData} dataFull={dataFull} dataSect={dataSect} distance={raceDist}/>
            </span>
            <span style={{display: displayDistanceToFirst ? 'block' : 'none'}}>
                <RaceSpectatorDistanceToFirst waitingData={!hasData} sizing={sizing} data={dataFull} distance={raceDist} />
            </span>
            {/* <RaceSpectatorSectionals waitingData={!hasData} dataFull={dataFull} dataSect={dataSects} distance={raceDist} /> */}
            <span style={{display: displayChart ? 'block' : 'none'}}>
                <RaceSpectatorChart waitingData={!hasData} speeds={speeds} colors={colors}/>
            </span>
            <span style={{display: displayRanking ? 'block' : 'none'}}>
                <RaceSpectatorRanking waitingData={!hasData} dataFull={dataFull} dataSect={dataSects} distance={raceDist} />
            </span>
        </div>
    );
}
