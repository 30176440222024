import { Avatar, Table } from "antd";

export function RaceDataSectional({door, data}) {
    const columns = [
      {
        title: 'Rank',
        dataIndex: 'PosC',
        key: 'rank',
        ellipsis: true,
        className: 'sec-col-rank',
      },
      {
        title: '#',
        dataIndex: 'NumP',
        key: 'num',
        ellipsis: true,
        className: 'sec-col-num',
        render: (value, record) => {
          return <Avatar style={{ backgroundColor: `#${record.color}`, color: `#${record.font}`, border: `1px solid #${record.border}`, verticalAlign: 'middle' }} size={25}>
          {value}
          </Avatar>
        },
      },
      {
        title: 'Dist/1st',
        dataIndex: 'Eprm',
        key: 'timefirst',
        ellipsis: true,
        className: 'sec-col-timefirst',
        render: text => text !== 'leader' ? <span>+{text}</span> : <span>{text}</span>,
      },
      {
        title: 'Time',
        dataIndex: 'HumanTcle',
        key: 'time',
        ellipsis: true,
        className: 'sec-col-time',
        render: (text, record) => <>{record.HumanTcle}</>
      },
      {
        title: 'Max Speed',
        dataIndex: 'Vmax',
        key: 'maxspeed',
        ellipsis: true,
        className: 'sec-col-maxspeed',
      },
    ];
  
    const list = Object.keys(data)
        .map(key => ({...data[key], key: data[key].NumP}))
        .sort((a,b) => a.NumP < b.NumP ? -1 : 1);

    return (
        <div className="race_live_sectional">
            <span className="sectional_title">{door}</span>
            <Table 
                columns={columns}
                dataSource={list}
                className="sec_block"
                pagination={false}
                />  
        </div>
    );
  }
  