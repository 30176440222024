const CET_SUFFIX = ' CET';

export function RaceLiveHeader({heure, prefix, meeting, race, venue, label}) {
    if (!heure) {
        return (
            <div className="race_live_header">
                <h1 className="race_live_title">
                    No more race today
                </h1>
            </div>
        )
    }
    return (
        <div className="race_live_header">
            <h1 className="race_live_title">
                <span className="race_live_prefix">{prefix}</span>
                {heure}{CET_SUFFIX}
                <span className="race_live_rc">{`R${meeting}C${race}`}</span>
                MEETING {meeting} - {venue} / RACE {race} - {label}
            </h1>
        </div>
    );
}
  
  