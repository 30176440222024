import React, {useState, useEffect} from "react";
import live from "../services/live.service";
import { Button, Table, Tabs } from "antd";

const { TabPane } = Tabs;

export default function PrivateLive() {
  const [status, set_status] = useState(false);
  const [output, set_output] = useState([]);
  const [interval_tail, set_interval_tail] = useState();
  const [connected_list, set_connected_list] = useState([]);
  const [interval_connected, set_interval_connected] = useState();
  const [loading, set_loading] = useState(false);

  const connected_columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Nom',
      dataIndex: 'email',
      key: 'email',
      render: (value, record) => {
        return <>
          <p key={record.id + "_name"}>{record.username}</p>
          <p key={record.id + "_email"}>{record.email}</p>
        </>
      }
    },
    {
      title: 'Socket',
      dataIndex: 'socket',
      key: 'socket',
      render: (value, record) => {
        return <>
          <p key={record.socket}>{record.socket}</p>
        </>
      }
    }
  ]

  // status_live -----------------------------------------------------
  const status_live = async () => {
    live
      .status()
      .then(r => {
        if (r && r.data) {
          set_status(r.data);
        }
      })
  }

  // start_live -----------------------------------------------------
  const start_live = async () => {
    live
      .start()
      .then(r => {
        status_live();
      })
  }

  // stop_live -----------------------------------------------------
  const stop_live = () => {
    live
      .stop()
      .then(r => {
        set_output([]);
        status_live();
      })
  }

  // tail_live -----------------------------------------------------
  const tail_live = () => {
    live
      .tail()
      .then(r => {
        if (r && r.data !== '') {
          let s_msg =  JSON.stringify(r.data); 
          set_output(old => {
            if (old.length > 100) {
              return [s_msg];
            }
            return old.concat(s_msg);
          });  
        }
      })
  }

  // connected_live -------------------------------------------------
  const connected_live = () => {
    live
      .connected()
      .then(r => {
        if (r && r.data !== '') {
          set_connected_list(r.data);
        }
      })
  }

  // start_tail -----------------------------------------------------
  const start_tail = () => {
    let int = setInterval(tail_live, 1000);
    set_interval_tail(int);
  }

  // start_connected ------------------------------------------------
  const start_connected = () => {
    let int = setInterval(connected_live, 1000);
    set_interval_connected(int);
  }

  // useEffect ------------------------------------------------------
  useEffect(() => {
    set_loading(false);
    status_live();
    return () => {
      clearInterval(interval_tail);
      clearInterval(interval_connected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (status) {
      start_tail();
      start_connected();
    } else {
      clearInterval(interval_tail);
      clearInterval(interval_connected);
    }
    return () => {
      clearInterval(interval_tail);
      clearInterval(interval_connected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  // render ---------------------------------------------------------
  return (
    <div className="private_page_wrapper">

      <div className="header">
        <span className="title">Live data</span>
        <span className="subtitle">Serveur JSON LIVE</span>
      </div>

      <div className="external_data_tabs">
        <Tabs defaultActiveKey="1" size="small" style={{ marginBottom: 32 }}>

          <TabPane tab="Statut" key="1">
            <div className="settings_toolbar">
              {!status && <Button className="success" onClick={start_live}>START LIVE</Button>}
              {status && <Button className="error" onClick={stop_live}>STOP LIVE</Button>}
            </div>
            <p>la console affiche le live et se purge au bout de 1000 lignes</p>
            <div className="terminal_console">{output.map((line, i) => {
              return <p key={"line"+i}>{line}</p>
            })}</div>
            <p>liste des log téléchargeables</p>
          </TabPane>

          <TabPane tab="Clients" key="2">
            <p>client connectés</p>
            <div className="data_list">
            <Table 
              columns={connected_columns} 
              dataSource={connected_list} 
              rowKey="id"
              loading={loading}
              className="common_list"
            />
          </div>

          </TabPane>

          <TabPane tab="Historique" key="3">
            <p>Liste des sessions avec bouton replay (pour client type admin)</p>
          </TabPane>

        </Tabs>
      </div>

    </div>
  )

}