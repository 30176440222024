import { useEffect, useRef, useState } from "react";

const COLOR_BACKGROUND = '#262626';
const COLOR_LEGEND = '#fff';

const COLOR_LINK_POSITION = '#fff';

const SIZE_PADDING_TOP = 10;
const SIZE_COMPETITOR_HEIGHT = 30;

function reformatColor(color) {
    if (!color) {
        return '#888888';
    }
    if (color.startsWith('#')) {
        return color;
    }
    if (color.length === 6 && color.match(/^[a-f0-9A-F]*$/)) {
        return '#' + color;
    }
    return color
}

function drawRankingLegend(ctx, step, data, colors) {
    let pcIndex = 0;
    for (let pc in data) {
        const x = 20 + (step / 2) + (step * pcIndex);
        const y = SIZE_PADDING_TOP + (Object.keys(colors).length * SIZE_COMPETITOR_HEIGHT);
        const lineHeight = 12;
        ctx.save();
        ctx.beginPath();
        // ctx.lineWidth = 1;
        ctx.fillStyle = COLOR_LEGEND;
        ctx.font = `${lineHeight}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
        ctx.translate(x, y);
        ctx.rotate(- Math.PI / 4);
        ctx.textAlign = 'right';
        ctx.fillText(pc, 0, lineHeight / 2);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
        pcIndex++;
    }
}

function drawRankingLine(ctx, step, data, colors) {
    let pcIndex = 0;
    let pcPrevious = null;
    for (let pc in data) {
        const x = 20 + (step / 2) + (step * pcIndex)

        let position = 1;
        for (let indexCompetitor in data[pc]) {
            const competitor = data[pc][indexCompetitor]
            const y = SIZE_PADDING_TOP + (position * SIZE_COMPETITOR_HEIGHT);
            if (pcPrevious && data[pcPrevious].indexOf(Number(competitor)) !== -1) {
                const previousPosition = data[pcPrevious].indexOf(Number(competitor)) + 1;
                const previousX = 20 + (step / 2) + (step * (pcIndex - 1))
                const previousY = SIZE_PADDING_TOP + (previousPosition * SIZE_COMPETITOR_HEIGHT);
                // ctx.strokeStyle = reformatColor(colors[competitor].stroke);
                ctx.strokeStyle = COLOR_LINK_POSITION
                ctx.beginPath();
                ctx.lineWidth = 0.5;
                ctx.moveTo(previousX, previousY);
                ctx.lineTo(x, y);
                ctx.closePath();
                ctx.stroke();
            }
            position++;
        }
        pcPrevious = pc;
        pcIndex++;
    }
}

function drawRankingTopXLine(ctx, data, width) {
    const nbCompetitors = Object.keys(data).map(key => data[key]).map(list => list.length).reduce((max, length) => Math.max(max, length), 0)
    const tops = [{
        position: 5,
        color: '#fff', // '#ff0000',
    },{
        position: 10,
        color: '#ddd', // '#e86e09',
    },{
        position: 15,
        color: '#bbb', // '#e3af14',
    },{
        position: 15,
        color: '#999', // '#dbc235',
    }].filter(item => item.position < nbCompetitors);
    tops.forEach(top => {
        const x0 = 20;
        const x1 = width - 20;
        const y = SIZE_PADDING_TOP + (top.position * SIZE_COMPETITOR_HEIGHT) + (SIZE_COMPETITOR_HEIGHT / 2);
        ctx.strokeStyle = top.color;
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.setLineDash([10, 10]);
        ctx.moveTo(x0, y);
        ctx.lineTo(x1, y);
        ctx.closePath();
        ctx.stroke();
        ctx.lineWidth = 1;
        ctx.setLineDash([]);
    })
}

function drawRankingJersey(ctx, step, data, colors) {
    let pcIndex = 0;
    for (let pc in data) {
        const x = 20 + (step / 2) + (step * pcIndex)

        let position = 1;
        for (let indexCompetitor in data[pc]) {
            const competitor = data[pc][indexCompetitor]
            const y = SIZE_PADDING_TOP + (position * SIZE_COMPETITOR_HEIGHT);

            ctx.beginPath();
            ctx.fillStyle = reformatColor(colors[competitor]?.fill);
            ctx.strokeStyle = reformatColor(colors[competitor]?.stroke);
            ctx.arc(x, y, 10, 0, 2*Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();

            ctx.beginPath();
            const lineHeight = 12;
            ctx.font = `${lineHeight}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
            ctx.fillStyle = reformatColor(colors[competitor].stroke);
            // const label = '' + position
            const label = '' + competitor
            ctx.fillText(label, x - (label.length * (lineHeight / 3)), y + (lineHeight / 3));
            ctx.closePath();
            ctx.fill();

            position++;
        }
        pcIndex++;
    }
}

function drawRanking(ctx, width, height, data, colors, distance) {
    const nbPassedDoors = Object.keys(data).length;
    const nbCalculatedDoors = Math.ceil(distance / 200); // 900m => PC800, PC600, PC400, PC200, PCARR => on arrondi au dupérieur car la première porte ne fait que 100m
    const nbDoors = Math.max(nbPassedDoors, nbCalculatedDoors);
    const step = (width - 20 - 20) / nbDoors;

    ctx.fillStyle = COLOR_BACKGROUND;
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.closePath();
    ctx.fill();

    drawRankingTopXLine(ctx, data, width);
    drawRankingLegend(ctx, step, data, colors);
    drawRankingLine(ctx, step, data, colors);
    drawRankingJersey(ctx, step, data, colors);
}

function drawWaiting(ctx, width, height) {
    ctx.fillStyle = COLOR_BACKGROUND;
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = COLOR_LEGEND;
    ctx.font = "48px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif";
    ctx.fillText("Waiting data", 10, 50);
}


export function RaceSpectatorRanking({waitingData, dataFull, dataSect, distance}, _ref) {

    const [width, setWidth] = useState(300);
    const [height, setHeight] = useState(300);
    const [lists, setLists] = useState({});
    const [colors, setColors] = useState({});

    const refContainer = useRef(null);
    const refCanvas = useRef(null);

    useEffect(() => {
        const nbCompetitors = Object.keys(dataFull).length;
        setHeight( (nbCompetitors + 1 ) * SIZE_COMPETITOR_HEIGHT + SIZE_PADDING_TOP + 30);
    }, [dataFull]);

    useEffect(() => {
        const _lists = {};
        for (let pc in dataSect) {
            if (pc.match(/^PC[0-9]+|PCARR$/)) {
                _lists[pc] = Object
                    .keys(dataSect[pc])
                    .filter(key => key !== 'nameDcle' && key !== 'previousDcle' && key !== 'indexDcle')
                    .map(key => dataSect[pc][key])
                    .sort((c1, c2) => c1.PosC < c2.PosC ? -1 : 1)
                    .map(c => c.NumP);
            }
        }
        setLists(_lists);
    }, [dataSect]);

    useEffect(() => {
        const _colors = {};
        for(let nump in dataFull) {
            _colors[nump] = {
                fill: dataFull[nump].color,
                stroke: dataFull[nump].border,
            };
        }
        setColors(_colors);
    }, [dataFull]);

    useEffect(() => {
        setWidth(refContainer.current.clientWidth);
    }, [dataFull]);

    useEffect(() => {
        const canvas = refCanvas.current;
        const context = canvas.getContext('2d');
        if (waitingData) {
            drawWaiting(context, width, height);
        } else {
            drawRanking(context, width, height, lists, colors, distance);
        }
    }, [waitingData, width, height, lists, colors, distance]);

    return (
        <div className="race_spectator_ranking">
            <h1 className="race_spectator_subtitle">Ranking</h1>
            <div className="race_spectator_ranking_block" ref={refContainer} >
                <canvas ref={refCanvas} id="ranking_canvas" width={width} height={height}/>
                {/* <RaceSpectatorPerformancesData dataFull={dataFull} dataSect={dataSect} distance={distance}/> */}
            </div>
        </div>
    )
}
