import { Avatar, Table } from "antd";

export function RaceDataCurrent({race_started, current_door, data}) {
    // columns_current
    const columns = [
      {
        title: 'Rank',
        dataIndex: 'PosC',
        key: 'rank',
        ellipsis: true,
        className: 'sec-col-rank',
      },
      {
        title: '#',
        dataIndex: 'NumP',
        key: 'num',
        ellipsis: true,
        className: 'sec-col-num',
        render: (value, record) => {
          return <Avatar style={{ backgroundColor: `#${record.color}`, color: `#${record.font}`, border: `1px solid #${record.border}`, verticalAlign: 'middle' }} size={25}>
          {value}
          </Avatar>
        },
      },
      {
        title: 'Inst. Speed',
        dataIndex: 'Vit',
        key: 'instspeed',
        ellipsis: true,
        className: 'sec-col-time',
      },
      {
        title: 'Dist/1st',
        dataIndex: 'Eprm',
        key: 'distfirst',
        ellipsis: true,
        className: 'sec-col-timefirst',
      },
      {
        title: 'Actual dist',
        dataIndex: 'DrDp',
        key: 'actdist',
        ellipsis: true,
        className: 'sec-col-maxspeed',
      },
    ];
  
    return (
      <div className="sec_block_current">
        <div className={race_started ? "race_live_sectional live" : "race_live_sectional"}  >
          <span className="sec_block_pc_live">
            {current_door}
          </span>
          <Table 
            columns={columns} 
            dataSource={data} 
            className="sec_block finish"
            pagination={false}
            tableLayout="fixed" 
          />
        </div>
      </div>
    );
  }
  
  