import React from "react";

export default function PrivateSettings() {

  return (
    <div className="private_page_wrapper">
      <h1>Settings</h1>
    </div>
  )

}