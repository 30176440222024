import React, {useEffect} from "react";
import {HashRouter, Route, Switch, useHistory, useLocation} from "react-router-dom";
// import * as logzioLogger from 'logzio-client-side';
import PrivateRoute from "./services/privateRoute";
// components
import PublicLayout from './components/publicLayout'
import PrivateLayout from './components/privateLayout'
// public pages
import Login from "./pages/login";
// private pages
import PrivateDashboard from "./pages/privateDashboard";
import PrivateRaceCalendar from "./pages/privateRaceCalendar";
import PrivateRaceSectionalLive from "./pages/privateRaceSectionalLive";
import PrivateLive from "./pages/privateLive";
import PrivateUserList from "./pages/privateUserList";
import PrivateUserForm from "./pages/privateUserForm";
import PrivateSettings from "./pages/privateSettings";
import Spectators from "./pages/spectators";

// css
import "antd/dist/antd.css";
import './styles/_vars.css';
import './styles/base.css';
import './styles/public_layout.css';
import './styles/login.css';
import './styles/private_layout.css';
import './styles/terminal_console.css';
import './styles/private_page.css';
import './styles/list.css';
import './styles/form.css';
import './styles/settings.css';
import './styles/private_external_data.css';
import './styles/private_race_calendar.css';
import './styles/private_race_sectional_live.css';
import './styles/spectator.css';
import Spectator from "./pages/spectator";
import PrivateInjectMeeting from "./pages/privateInjectMeeting";

require('dotenv').config();

export default function App() {
    const location = useLocation();
    const history = useHistory();


    // const logger = logzioLogger.Logger({
    //     url: `${process.env.REACT_APP_SERVER_URL}/api/log`
    // });

    useEffect(() => {
        if (history.location.hash === '#/') {
            history.push(`#/racelist`);
            window.location.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Switch>
            <HashRouter>

                {/* public pages */}
                <Route path={["/login"]}>
                    <PublicLayout>
                        <Login/>
                    </PublicLayout>
                </Route>

                {/* private pages */}
                <PrivateRoute path={["/dashboard"]}>
                    <PrivateLayout>
                        <PrivateDashboard/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/racelist"]}>
                    <PrivateLayout>
                        <PrivateRaceCalendar/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/racelive/:source/:RxCx"]}>
                    <PrivateLayout>
                        <PrivateRaceSectionalLive/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/spectators"]}>
                    <PrivateLayout>
                        <Spectators/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/spectator/:source/:RxCx"]}>
                    <PrivateLayout>
                        <Spectator/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/live"]}>
                    <PrivateLayout>
                        <PrivateLive/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/inject-meeting"]}>
                    <PrivateLayout>
                        <PrivateInjectMeeting/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/users"]}>
                    <PrivateLayout>
                        <PrivateUserList/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/user/:uuid?"]}>
                    <PrivateLayout>
                        <PrivateUserForm/>
                    </PrivateLayout>
                </PrivateRoute>
                <PrivateRoute path={["/settings"]}>
                    <PrivateLayout>
                        <PrivateSettings/>
                    </PrivateLayout>
                </PrivateRoute>

            </HashRouter>
        </Switch>
    )

}
