import { useEffect, useRef, useState } from "react";

import { RaceSpectatorPerformancesData } from "./RaceSpectatorPerformancesData";
import { RaceSpectatorPerformancesMap } from "./RaceSpectatorPerformancesMap";


export function RaceSpectatorPerformances({waitingData, dataFull, dataSect, distance}, _ref) {

    const refContainer = useRef(null);
    const refData = useRef(null);

    const [mapConfig, setMapConfig] = useState(null);

    useEffect(() => {
        const widthContainer = refContainer.current.clientWidth;
        const widthData = refData.current.clientWidth + 20; // +20px => .race_spectator_performances_data { margin-right: 20px;}
        const heightData = refData.current.clientHeight;
        const remains = widthContainer - widthData - 5;
        if (remains > 300) {
            setMapConfig({
                mode: 'inline',
                maxWidth: remains,
                maxHeight: heightData,
            })
        } else {
            setMapConfig({
                mode: 'under',
                maxWidth: widthContainer,
            })
        }
    }, [dataFull]);

    return (
        <div className="race_spectator_performances" ref={refContainer}>
            <h1 className="race_spectator_subtitle">Performances</h1>
            <div className="race_spectator_performances_block race_spectator_performances_data" ref={refData}>
                <RaceSpectatorPerformancesData waitingData={waitingData} dataFull={dataFull} dataSect={dataSect} distance={distance}/>
            </div>
            <div className="race_spectator_performances_block race_spectator_performances_map">
                <RaceSpectatorPerformancesMap waitingData={waitingData} dataFull={dataFull} config={mapConfig}/>
            </div>
        </div>
    )
}