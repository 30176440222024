import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { 
  CalendarOutlined,
  TeamOutlined,
  LogoutOutlined,
  FundViewOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { useAuth } from '../services/auth.service';
import { Link } from "react-router-dom";

const { Sider, Content, Footer } = Layout;

export default function PrivateLayout(props) {
  const auth = useAuth();
  const [collapsed, set_collpased] = useState(true);
  const [user, set_user] = useState({});

  // useEffect ------------------------------------------------------
  useEffect(() => {
    set_collpased(true);
    set_user(JSON.parse(localStorage.getItem('user')));
  }, [])  

  useEffect(() => {
  }, [user])  


  return (
    <Layout className="private_layout">
      <Sider collapsible className="sider" trigger={null} collapsed={collapsed}>
        <div className="logo">
          <Link to="/racelist">
            <img src={`${process.env.PUBLIC_URL}/images/logo/logo.png`} alt="McLloyd" />
          </Link>
        </div> 
        <div className="menu_wrapper">
          <Menu mode="inline" className="menu">
            <Menu.Item key="1" icon={<CalendarOutlined />}>
              <Link to="/racelist">Courses</Link>
            </Menu.Item>
            <Menu.Item key="spectators" icon={<FundViewOutlined />}>
              <Link to="/spectators">Spectator</Link>
            </Menu.Item>
          </Menu>
          <Menu mode="inline" className="menu bottom">
            {user.roles && user.roles.includes("admin") && <>
              {/* <Menu.Item key="2" icon={<WifiOutlined />}>
                <Link to="/live">Server Live</Link>
              </Menu.Item>*/}
              <Menu.Item key="7" icon={<CloudUploadOutlined />}>
                <Link to="/inject-meeting">Injection de réunion</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<TeamOutlined />}>
                <Link to="/users">Utilisateurs</Link>
              </Menu.Item>
              {/* <Menu.Item key="5" icon={<SettingOutlined />}>
                <Link to="/settings">Settings</Link>
              </Menu.Item> */}
              </>
            }
            <Menu.Item key="6" onClick={() => auth.logout()} icon={<LogoutOutlined />}>
              logout
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
      <Layout id="page_wrapper" className="layout">
        <Content>{props.children}</Content>
        <Footer className="private_layout_footer"><p>Version {`${process.env.REACT_APP_VERSION}`}</p></Footer>
      </Layout>
    </Layout>
  )
}