import { useState } from "react";

import { RaceLiveHeader } from "./RaceLiveHeader";
import { RaceLiveOverview } from "./RaceLiveOverview";
import { RaceLiveBody } from "./RaceLiveBody";
import { RaceLiveFooter } from "./RaceLiveFooter";

import RaceLiveService from "../../services/race-live.service";

function humanTcle(tcle) {
    if (!tcle) {
        return undefined;
    }
    let reste = tcle;
    const ms = reste % 1000;
    const sms = `${ms<100?'0':''}${ms<10?'0':''}${ms}`
    reste = (reste - ms) / 1000;
    const sec = reste % 60;
    const ssec = `${sec<10?'0':''}${sec}`
    reste = (reste - sec) / 60;
    const min = reste;
    const smin = `${min<10?'0':''}${min}`
    return `${smin}:${ssec}.${sms}`;
}

export function RaceLive(){
    // UI blocks
    const [current_block, set_current_block] = useState([]);
    const [current_door, set_current_door] = useState('waiting sectional data ...');
    const [doors, set_doors] = useState({});
    const [finish_block, set_finish_block] = useState([]);
    const [current_top5, set_current_top5] = useState([]);
    const [finish_top5, set_finish_top5] = useState([]);
    // UI infos
    const [heurec, set_heurec] = useState(null);
    const [hippodrome, set_hippodrome] = useState(null);
    const [numc, set_numc] = useState(null);
    const [numr, set_numr] = useState(null);
    const [libc, set_libc] = useState(null);
    const [prefix, set_prefix] = useState(null);
    const [race_dist, set_race_dist] = useState(null);
    const [racetrack_percent, set_racetrack_percent] = useState(0);
    const [race_type, set_race_type] = useState(null);
    // race status
    const [race_started, set_race_started] = useState(false);
    const [race_ended, set_race_ended] = useState(false);
    
    useState(() => {
        RaceLiveService.callBackOnUpdate(updates => {
            const keys = Object.keys(updates);
            if (keys.length > 0) {
                if (updates.reinit) {
                    set_current_block([]);
                    set_current_door('Waiting sectional data ...');
                    set_doors({});
                    set_finish_block([]);
                    set_current_top5([]);
                    set_finish_top5([]);
                    // UI infos
                    set_heurec(null);
                    set_hippodrome(null);
                    set_numc(null);
                    set_numr(null);
                    set_libc(null);
                    set_prefix(null);
                    set_race_dist(null);
                    set_racetrack_percent(0);
                    set_race_type(null);
                    // race status
                    set_race_started(false);
                    set_race_ended(false);
                }
    
                if (updates.numc !== undefined) {set_numc(updates.numc);}
                if (updates.numr !== undefined) {set_numr(updates.numr);}
                if (updates.dist !== undefined) {set_race_dist(updates.dist);}
                if (updates.hippodrome !== undefined) {set_hippodrome(updates.hippodrome);}
                if (updates.heurec !== undefined) {set_heurec(updates.heurec);}
                if (updates.libc !== undefined) {set_libc(updates.libc);}
                if (updates.prefix !== undefined) {set_prefix(updates.prefix);}
                if (updates.details !== undefined && updates.details.length > 0) {set_race_type(updates.details[0].disc);}
        
                if (updates.captures !== undefined) {
                    const data = updates.captures
                        .filter(capture => capture.PosC > 0)
                        .sort((a, b) => a.PosC < b.PosC ? -1 : 1)
                        .map(value => ({...value, key: value.NumP}))
    
                    updateProgression(data);
                    set_current_block(data);
                    const top = data.slice(0,5);
                    set_current_top5(top);
                }
        
                if (updates.doors !== undefined) {
                    updates.doors
                        .map(sectional => ({
                            ...sectional,
                            HumanTcle: humanTcle(sectional.Tcle),
                        }))
                        .forEach(sectional => {
                            doors[sectional.Dcle] = doors[sectional.Dcle] || {};
                            doors[sectional.Dcle][sectional.NumP] = sectional
                        })
                    set_doors(doors);
                    set_finish_block(doors.PCARR);
                    if (doors.PCARR) {
                        const top = Object
                            .keys(doors.PCARR)
                            .map(key => doors.PCARR[key])
                            .map(data => ({
                                ...data,
                                key: data.NumP,
                            }))
                            .sort((a, b) => a.PosC < b.PosC ? -1 : 1)
                            .slice(0,5);
                        set_finish_top5(top);
                    } else {
                        set_finish_top5([]);
                    }
                }
        
                if (updates.race_start !== undefined) {
                    set_race_started(updates.race_start);
                }
                if (updates.race_end !== undefined) {
                    set_race_ended(updates.race_end)
                }
                if (updates.race_start !== undefined && updates.race_end !== undefined) {
                    if (!updates.race_start && !updates.race_end) {
                        set_racetrack_percent(0);
                        set_current_door(`Waiting for data`);
                    } else if (updates.race_start && !updates.race_end) {
                        set_current_door(`Section in progress`);
                    } else if (updates.race_start && updates.race_end) {
                        set_current_door(`FINISH`);
                    } else if (!updates.race_start && updates.race_end) {
                        console.warn('END without START')
                        set_current_door(`FINISH`);
                    }
                }
            }
        });
    })

    const updateProgression = async (details) => {
        if (race_ended) {
            set_racetrack_percent(100);
        } else {
            const total = RaceLiveService.getCurrentDistance();
            if (isNaN(total)) {
                return;
            }
            const first = details.find(detail => detail.PosC === 1);
            if (!first) {
                return;
            }
            const parcouru = Math.floor(Number(first.DrDp));
            if (!isNaN(parcouru)) {
                const percent = Math.min(100, Math.floor( parcouru / total * 100));
                set_racetrack_percent(prev => percent <= prev ? prev : percent)
            }
        }
    }

    return (
        <div className="race_live_component">
            <RaceLiveHeader heure={heurec} prefix={prefix} meeting={numr} race={numc} venue={hippodrome} label={libc}/>
            <RaceLiveOverview started={race_started} ended={race_ended} distance={race_dist} type={race_type} finishers={finish_top5} inprogress={current_top5} percent={racetrack_percent}/>
            <RaceLiveBody race_started={race_started} race_ended={race_ended} heurec={heurec} doors={doors} current_door={current_door} finish_block={finish_block} current_block={current_block} />
            <RaceLiveFooter />
        </div>
    );
}